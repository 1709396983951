import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import SEO from '../../components/seo'

const Success = () => {
  const [count, setCount] = useState(7)
  useEffect(() => {
    setTimeout(() => {
      setCount((prevState) => prevState - 1)
    }, 1000)
    if (!count) navigate('/')
  }, [count])

  return (
    <>
      <SEO
        title="Спасибо вам!"
        description="This page is automatically redirected"
      />
      <div id="main" style={{ height: '100vh', padding: '150px 50px' }}>
        <section id="one">
          <h1>
            Спасибо вам! <span role="img">📫</span>
          </h1>
          <p>
            "Ваше сообщение было успешно отправлено. Мы получили его и свяжемся
            с вами в ближайшее время."
          </p>
          <p>
            Эта страница автоматически перенаправляется через {count}. Хотя,
            если вы не хотите ждать, вы можете использовать эту{' '}
            <Link to="/">ссылку</Link>
          </p>
        </section>
      </div>
    </>
  )
}

export default Success
